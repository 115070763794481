<script>


import UploadService from "@/services/upload-service";

/**
 * Patients Component
 */
export default {
  components: {
    
  },
  data() {
    return {
      title: "upload",
      items: [
        {
          text: this.$t("all.admin"),
        },
        {
          text: "Upload",
          active: true,
        },
      ],
    }
  },

  created() {
    this.uploadService = new UploadService(this.$http);
  
  },

  
  methods: {
    
    
    async setUpload(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append('file', file, file.name);

      var payloadUpload = {
        type: file.name, 
        isPublic: false,
        form: formData
      }
      console.log("Payload: " + payloadUpload)
      this.uploadService.upload(payloadUpload).then((response)=> {
        this.imagens.push(response.data)
          
      })
    },
    
  },
  
};
</script>

<template>
  
 
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              
            </div>
              Formulario
              <form enctype="multipart/form-data">
                <input type="file" @change="setUpload"  name="uploadFieldName"  
            accept="image/*,application/pdf" class="input-file" >
            
            

              </form>

          </div>
        </div>
      </div>
    </div>
    