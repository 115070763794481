
var endpoint = '/upload/post'
export default class SellerService {
  constructor(axios){
    this.axios = axios
  }

  upload(file) {
    // `upload/post?public=${payload.isPublic}&type=${payload.type}`
    return this.axios.post(`${endpoint}?public=${file.isPublic}&type=${file.type}/`, file.form)
  }
}
